import React, { Fragment } from 'react';
import styledSystem from '@emotion/styled';
import { graphql } from 'gatsby';
import LayoutAuth from '../components/layoutAuth';

const Root = styledSystem.div`
  position: relative;
  top: 15vh;
  min-height: 65vh;
`;

const LandingPage = () => (
  <>
    <h1>Sign In or Register</h1>
  </>
);

const Named = () => (
  <Root>
    <LandingPage />
    <LayoutAuth />
  </Root>
);

export default Named;

export const pageQuery = graphql`
  query pageAuthContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "pageAuth" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
