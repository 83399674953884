import React, { Component } from 'react';

import Navigation from './Navigation/indexAuth';
import getFirebase, { FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';

class LayoutAuth extends Component {
  constructor(props) {
    super(props);

    this.state = { firebase: null };
  }

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');

    Promise.all([app, auth, database]).then((values) => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    const { state } = this;
    return (
      <FirebaseContext.Provider value={state.firebase}>
        <AppWithAuthentication {...this} />
      </FirebaseContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(({ props }) => {
  return (
    <>
      <Navigation />
      <hr />
      {props.children}
    </>
  );
});

export default LayoutAuth;
